/*============================================================================================*/
/* Detail page */
/*============================================================================================*/
/*-------- Page head --------*/
.detail_page_head .title {
  float: left;
}
@media (max-width: 767px) {
  .detail_page_head .title {
    padding-top: 0;
    float: none;
  }
}
.detail_page_head .title h1 {
  font-size: 28px;
  font-size: 1.75rem;
  margin: 0;
}
@media (max-width: 767px) {
  .detail_page_head .title h1 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
.detail_page_head .title ul.tags {
  margin: 8px 0 25px 0;
  padding: 0;
}
@media (max-width: 767px) {
  .detail_page_head .title ul.tags {
    margin: 8px 0 20px 0;
  }
}
.detail_page_head .title ul.tags li {
  display: inline-block;
  margin-right: 3px;
}
.detail_page_head .title ul.tags li a {
  border: 1px solid #ededed;
  padding: 2px 10px;
  line-height: 1;
  color: #777;
  font-size: 13px;
  font-size: 0.8125rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.detail_page_head .title ul.tags li a:hover {
  color: #e54750;
}
.detail_page_head .rating {
  float: right;
}
@media (max-width: 767px) {
  .detail_page_head .rating {
    padding-top: 0;
    float: none;
    display: inline-block;
    margin-bottom: 15px;
  }
}
.detail_page_head .rating .score span {
  top: 5px;
  font-size: 14px;
  font-size: 0.875rem;
  display: inline-block;
  position: relative;
  margin-right: 8px;
  text-align: right;
  line-height: 1.1;
  font-weight: 500;
}
.detail_page_head .rating .score span em {
  font-size: 12px;
  font-size: 0.75rem;
  display: block;
  font-weight: normal;
}
@media (max-width: 767px) {
  .detail_page_head .rating .score span {
    float: right;
    text-align: left;
    margin: 0 0 0 8px;
  }
}
.detail_page_head .rating .score strong {
  font-size: 18px;
  font-size: 1.125rem;
  background-color: #66cc66;
  -webkit-border-radius: 5px 5px 5px 0;
  -moz-border-radius: 5px 5px 5px 0;
  -ms-border-radius: 5px 5px 5px 0;
  border-radius: 5px 5px 5px 0;
  padding: 8px;
  line-height: 1.1;
  color: #fff;
}
.detail_page_head .rating .score strong:before {
  color: #fff;
}
@media (max-width: 767px) {
  .detail_page_head .rating .score strong {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.pictures {
  text-align: left;
}
@media (max-width: 767px) {
  .pictures {
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }
}
.pictures figure {
  margin: 0;
  overflow: hidden;
  position: relative;
  height: 100px;
  width: 32%;
  display: inline-block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .pictures figure {
    height: 90px;
  }
}
@media (max-width: 991px) {
  .pictures figure {
    width: 80px;
    height: 80px;
  }
}
.pictures figure a {
  display: block;
}
.pictures figure a span {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 26px;
  font-size: 1.625rem;
}
.pictures figure a img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  z-index: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pictures figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1.05);
  -moz-transform: translate(-50%, -50%) scale(1.05);
  -ms-transform: translate(-50%, -50%) scale(1.05);
  -o-transform: translate(-50%, -50%) scale(1.05);
  transform: translate(-50%, -50%) scale(1.05);
}

/*-------- Fixed Booking Panel--------*/
.box_order {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.box_order .head {
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  text-align: center;
  border-bottom: 1px solid #ededed;
  padding: 17px 20px 12px 20px;
  background-color: #454545;
  color: #fff;
}
.box_order .head h3 {
  font-size: 21px;
  font-size: 1.3125rem;
  margin: 0;
  color: #fff;
}
.box_order .main {
  padding: 25px;
}
.box_order .main ul {
  list-style: none;
  padding: 0;
}
.box_order .main ul li {
  margin-bottom: 8px;
}
.box_order .main ul li.total {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
}
.box_order .main ul li a {
  padding-left: 25px;
  color: #444;
  display: inline-block;
  position: relative;
}
.box_order .main ul li a:before {
  font-family: "ElegantIcons";
  content: "\4f";
  font-size: 16px;
  font-size: 1rem;
  position: absolute;
  left: 0;
  top: 2px;
  line-height: 1;
}
.box_order .main ul li a:hover {
  color: #e74747;
}
.box_order .main ul li span {
  float: right;
}
.box_order .form-group {
  margin: 0 0 10px 0;
}
.box_order textarea {
  height: 130px;
}

.box_order a.close_panel_mobile {
  position: absolute;
  right: 15px;
  top: 5px;
  color: #fff;
  font-size: 26px;
  font-size: 1.625rem;
  display: none;
}
@media (max-width: 991px) {
  .box_order.mobile_fixed {
    display: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    position: fixed;
    overflow-y: scroll;
    border: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #fff;
  }
  .box_order.mobile_fixed .head {
    padding: 10px 20px 10px 20px;
    position: relative;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }
  .box_order.mobile_fixed .head h3 {
    font-size: 18px;
    font-size: 1.125rem;
    text-transform: uppercase;
  }
  .box_order.mobile_fixed .head .offer {
    display: none;
  }
  .box_order.mobile_fixed .head a.close_panel_mobile {
    display: inline-block;
  }
  .box_order.mobile_fixed .main {
    padding-bottom: 50px;
  }
  .box_order.mobile_fixed .btn_1_mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 10px 6px 10px;
    background-color: #fff;
    border-top: 1px solid #ededed;
    z-index: 9999999;
  }
  .box_order.mobile_fixed .btn_1_mobile .text-center {
    display: none;
  }
}

.btn_reserve_fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 9999;
}
@media (max-width: 991px) {
  .btn_reserve_fixed {
    display: block;
  }
}

.secondary_nav {
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ededed;
  padding: 20px 0;
  position: relative;
}
.secondary_nav span {
  position: absolute;
  height: 7px;
  width: 100%;
  left: 0;
  bottom: 0;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}
.secondary_nav.is_stuck {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  border-top: none;
}
@media (max-width: 767px) {
  .secondary_nav.is_stuck {
    padding: 10px 0;
  }
}
.secondary_nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (max-width: 767px) {
  .secondary_nav ul {
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding: 6px 0;
  }
}
.secondary_nav ul li {
  display: inline-block;
  margin-right: 15px;
}
.secondary_nav ul li:last-child {
  margin-right: 0;
  border-left: 1px solid #ededed;
  padding-left: 18px;
}
.secondary_nav ul li:last-child a {
  background-color: black;
  background-color: transparent;
  padding: 3px 12px;
  border: 1px solid #d2d8dd;
}
.secondary_nav ul li a {
  color: #444;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px 15px;
}
.secondary_nav ul li a.active, .secondary_nav ul li a:hover {
  color: #e54750;
}
.secondary_nav ul li a i {
  margin-right: 8px;
}

.list_menu section {
  padding-top: 25px;
}

.opt_order {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
  padding: 15px 0 5px 0;
}

/*-------- Menu list --------*/
.table_wrapper {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.05);
  -moz-box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.05);
  box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.05);
}

a.menu_item {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: block;
  position: relative;
  padding: 20px 95px 15px 20px;
  color: #444;
  -webkit-box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.05);
  -moz-box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.05);
  box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.05);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  a.menu_item {
    margin-bottom: 15px;
  }
}
a.menu_item h3 {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 0;
}
a.menu_item p {
  color: #777;
  margin-bottom: 10px;
  font-size: 13px;
  font-size: 0.8125rem;
}
a.menu_item figure {
  width: 70px;
  height: 70px;
  overflow: hidden;
  position: absolute;
  margin: 0;
  right: 15px;
  top: 15px;
  border: 1px solid #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
a.menu_item figure img {
  width: 70px;
  height: auto;
}
a.menu_item:hover {
  -webkit-box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.1);
  -moz-box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.1);
  box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.1);
}
a.menu_item:hover h3 {
  color: #e54750;
}

.table.cart-list {
  border-bottom: none;
  background-color: transparent;
}
.table.cart-list.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.table.cart-list thead th {
  border: none;
  font-weight: 500;
}
.table.cart-list td {
  vertical-align: middle;
}
.table.cart-list td.options i {
  font-size: 26px;
}
.table.cart-list td h4 {
  margin: 0;
  font-size: 16px;
  font-size: 1rem;
}
.table.cart-list td p {
  margin: 0;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #444;
}
.table.cart-list td figure {
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: inline-block;
  float: left;
  margin: 0;
  margin-right: 15px;
  border: 1px solid #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.table.cart-list td figure img {
  width: 60px;
  height: auto;
}
@media (max-width: 767px) {
  .table.cart-list td figure {
    float: none;
  }
}
.table.cart-list td .dropdown-options {
  border: none;
  margin: 0;
}
.table.cart-list td .dropdown-options > a {
  padding: 0;
  display: inline-block;
  color: #e54750;
}
.table.cart-list td .dropdown-options > a:after {
  display: none;
}
.table.cart-list td .dropdown-options > a:hover {
  color: #111;
}
.table.cart-list td .dropdown-menu {
  background-color: #fff;
  min-width: 250px;
  margin-left: -105px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
@media (max-width: 991px) {
  .table.cart-list td .dropdown-menu {
    margin-left: -205px;
  }
}
.table.cart-list td .dropdown-menu label {
  font-weight: normal;
}
.table.cart-list td .dropdown-menu a {
  color: #fff;
}
.table.cart-list td .dropdown-menu h5 {
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
  margin-bottom: 10px !important;
  font-size: 14px;
  font-size: 0.875rem;
}
.table.cart-list td .dropdown-menu ul {
  list-style: none;
  padding: 0;
}
.table.cart-list td .dropdown-menu ul li label {
  font-size: 13px;
  font-size: 0.8125rem;
}
.table.cart-list td .dropdown-menu ul li label small {
  float: right;
  font-size: 13px;
  font-size: 0.8125rem;
}

#message {
  position: fixed;
  bottom: 15px;
  left: 15px;
  background-color: #e54750;
  color: #fff;
  padding: 12px 12px 10px 40px;
  font-weight: 500;
  line-height: 1;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  display: none;
}
#message:before {
  font-family: "ElegantIcons";
  content: "\52";
  font-size: 21px;
  font-size: 1.3125rem;
  position: absolute;
  left: 10px;
  top: 7px;
  line-height: 1;
}

.reviews #review_summary {
  text-align: center;
  background-color: #66cc66;
  color: #fff;
  padding: 20px 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
@media (max-width: 991px) {
  .reviews #review_summary {
    margin-bottom: 15px;
  }
}
.reviews #review_summary strong {
  font-size: 42px;
  font-size: 2.625rem;
  display: block;
  line-height: 1;
}
.reviews #review_summary em {
  font-style: normal;
  font-weight: 500;
  display: block;
}
.reviews .progress {
  margin-bottom: 12px;
  height: 5px;
}
.reviews .progress-bar {
  background-color: #66cc66;
}
.reviews .reviews_sum_details h6 {
  font-size: 14px;
  font-size: 0.875rem;
}
.reviews .reviews_sum_details strong {
  position: relative;
  top: -8px;
}

.review_card {
  background-color: #fff;
  border: 1px solid #ededed;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 25px 25px 10px 25px;
  margin-bottom: 30px;
}
.review_card .user_info {
  text-align: center;
}
@media (max-width: 767px) {
  .review_card .user_info {
    margin-bottom: 10px;
  }
}
.review_card .user_info figure {
  width: 50px;
  height: 50px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .review_card .user_info figure {
    float: left;
  }
}
.review_card .user_info figure img {
  width: 50px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.review_card .user_info h5 {
  font-size: 13px;
  font-size: 0.8125rem;
}
@media (max-width: 767px) {
  .review_card .user_info h5 {
    float: left;
    margin: 18px 0 0 15px;
  }
}
.review_card .review_content h4 {
  font-size: 18px;
  font-size: 1.125rem;
}
.review_card .review_content .rating {
  float: left;
  font-weight: 600;
  font-size: 26px;
  font-size: 1.625rem;
  color: #66cc66;
}
.review_card .review_content .rating small {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 600;
}
.review_card .review_content .rating strong {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 400;
  color: #444;
}
@media (max-width: 575px) {
  .review_card .review_content .rating {
    float: none;
    display: block;
  }
}
@media (max-width: 575px) {
  .review_card .review_content .rating em {
    float: none;
    display: inline-block;
    margin-left: 10px;
  }
}
.review_card .review_content em {
  color: #999;
  float: right;
}
@media (max-width: 575px) {
  .review_card .review_content em {
    float: none;
    display: block;
    margin-top: 10px;
    margin-left: 0;
  }
}
.review_card .review_content ul {
  margin: 35px 0 25px 0;
  padding: 0;
}
.review_card .review_content ul li {
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
  margin-right: 0;
}
@media (max-width: 575px) {
  .review_card .review_content ul li span {
    display: none;
  }
}
.review_card .review_content ul li a {
  background-color: #f0f0f0;
  padding: 7px 10px 5px 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  color: #444;
}
.review_card .review_content ul li a:hover {
  background-color: #e54750;
  color: #fff;
}
.review_card .review_content ul li a i {
  margin-right: 5px;
}
@media (max-width: 575px) {
  .review_card .review_content ul li a i {
    margin-right: 0;
  }
}
.review_card .review_content ul li:last-child {
  float: right;
  margin-right: 0;
}
.review_card .reply {
  padding-top: 30px;
}
@media (max-width: 767px) {
  .review_card .reply .user_info {
    display: none;
  }
}
.review_card .reply .user_info figure {
  width: 50px;
  height: 50px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
}
.review_card .reply .user_info figure img {
  width: 50px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.review_card .reply .user_info h5 {
  font-size: 14px;
  font-size: 0.875rem;
}
.review_card .reply .review_content {
  border-left: 5px solid #ededed;
  padding-left: 20px;
}
@media (max-width: 575px) {
  .review_card .reply .review_content strong {
    display: block;
  }
}
.review_card .reply .review_content em {
  color: #999;
  font-style: italic;
  float: right;
}
@media (max-width: 575px) {
  .review_card .reply .review_content em {
    float: none;
    display: block;
    margin-top: 10px;
  }
}

.dropdown {
  border: 1px dotted #c8c8c8;
  padding: 0;
  margin-bottom: 5px;
}
.dropdown a {
  padding: 15px 15px 12px 15px;
  line-height: 1;
  color: #444;
  font-weight: 500;
  display: block;
  position: relative;
}
.dropdown a:after {
  font-family: 'food';
  font-size: 21px;
  font-size: 1.3125rem;
  font-weight: bold;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 10px;
  top: 10px;
}
.dropdown.show a {
  color: #333;
}
.dropdown.show a:after {
  transform: rotateY(360deg);
  color: #333;
}
.dropdown .dropdown-menu {
  font-size: 14px;
  font-size: 0.875rem;
  background: #f8f8f8;
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding: 15px;
  width: 100.5%;
  left: -1px !important;
  right: -1px !important;
  margin: 5px 0 0 0;
}
.dropdown .dropdown-menu h4 {
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.dropdown.time a:after {
  content: '\0057';
}

.dropdown.time {
  margin-bottom: 25px;
}

.dropdown.day a:after {
  font-family: "ElegantIcons";
  content: "\e023";
  font-weight: 300;
}

.radio_select ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
}
.radio_select ul li {
  display: inline-block;
  margin: 0;
  width: 23.5%;
}
.radio_select.chose_day ul li {
  width: 43.5%;
}
.radio_select input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radio_select label {
  display: inline-block;
  text-align: center;
  width: 100%;
  padding: 8px;
  border: 1px dotted #c8c8c8;
  background-color: #fff;
  font-size: 16px;
  font-size: 1rem;
}
.radio_select label em {
  display: block;
  color: red;
  font-style: normal;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 500;
}
.radio_select label:hover {
  background-color: #dfd;
}
.radio_select input[type="radio"]:focus + label {
  border: 1px solid transparent;
  background-color: #e54750;
  color: #fff;
}
.radio_select input[type="radio"]:focus + label em {
  color: #ffff66;
}
.radio_select input[type="radio"]:checked + label {
  border: 1px solid transparent;
  background-color: #e54750;
  color: #fff;
}
.radio_select input[type="radio"]:checked + label em {
  color: #ffff66;
}

/*-------- Modal Order --------*/
#modal-dialog .content {
  padding: 20px 20px 0 20px;
}
#modal-dialog .content h5 {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 15px;
}
#modal-dialog .content ul {
  padding: 0;
  margin: 0 0 20px 0;
  list-style: none;
}
#modal-dialog .content ul li span {
  float: right;
}
#modal-dialog .footer {
  -webkit-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding: 15px;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  -ms-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}
@media (max-width: 767px) {
  #modal-dialog .footer .mb-mobile {
    margin-bottom: 5px;
  }
}

.numbers-row {
  position: relative;
  width: 100%;
  height: 40px;
  border: 2px solid #d2d8dd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 25px;
}
.numbers-row input {
  position: relative;
  width: 40px;
  height: 40px;
  text-align: center;
  left: 50%;
  padding: 5px;
  border: none;
  margin-left: -20px;
  font-weight: 500;
  background: none;
  font-size: 18px;
  font-size: 1.125rem;
}
.numbers-row input:focus {
  outline: none;
  box-shadow: none;
}
.numbers-row .button_inc {
  cursor: pointer;
  position: absolute;
  width: 33px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  z-index: 2;
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 300 !important;
  color: #999;
}
.numbers-row .button_inc:hover {
  color: #e54750;
}
.numbers-row .dec,
.numbers-row .inc {
  top: 2px;
}
.numbers-row .dec {
  left: 0;
}
.numbers-row .inc {
  right: 0;
}
