.box_order_form {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 25px;
  background-color: #fff;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.box_order_form .head {
  text-align: left;
  padding: 15px 20px 12px 25px;
  background-color: #454545;
  color: #fff;
}
.box_order_form .head h3 {
  font-size: 18px;
  font-size: 1.125rem;
  margin: 0;
  color: #fff;
}
.box_order_form .head small {
  display: block;
}
.box_order_form .head a {
  color: #fff;
  text-decoration: underline;
}
.box_order_form .main {
  padding: 20px 25px 10px 25px;
}
.box_order_form .form-group input {
  padding-right: 10px;
}
.box_order_form .form-group i {
  font-size: 16px;
  font-size: 1rem;
  position: absolute;
  right: 3px;
  top: 12px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
}
.box_order_form .form-group i.icon_mail {
  top: 10px;
}

.payment_select {
  background-color: #eeeeee;
  padding: 15px 15px 10px 15px;
  margin-bottom: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.payment_select label {
  display: inline-block;
  margin-bottom: 0;
}
.payment_select i {
  float: right;
  font-size: 24px;
}
.payment_select#paypal {
  background: #eeeeee url(../images/paypal_2.svg) center right no-repeat;
}

#confirm {
  text-align: center;
  padding: 60px 15px;
}

@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }
  100% {
    stroke-dashoffset: 480px;
  }
}
@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }
  100% {
    stroke-dashoffset: 480px;
  }
}
@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }
  100% {
    stroke-dashoffset: 480px;
  }
}
.inlinesvg .svg svg {
  display: inline;
}

.icon--order-success svg path {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}

#register_bg {
  background: #ccc url(../images/bg-food.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#register {
  -webkit-box-shadow: 3px 0 30px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 0 30px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 0 30px rgba(0, 0, 0, 0.2);
  width: 430px;
  padding: 30px 45px;
  /*position: absolute;
  left: 0;
  top: 0;*/
  /*min-height: 100vh;*/
  overflow-y: auto;
  background-color: #fff;
}
#register figure {
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #ededed;
  padding: 0 60px 30px 60px;
}
@media (max-width: 767px) {
  #register figure {
    margin: -30px -30px 20px -30px;
    padding: 20px 30px;
  }
}
@media (max-width: 767px) {
  #register {
    width: 100%;
    padding: 30px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
#register form {
  margin-bottom: 30px;
  display: block;
}
#register form .form-group input {
  padding-left: 40px;
}
#register form .form-group i {
  font-size: 18px;
  font-size: 1.125rem;
  position: absolute;
  left: 12px;
  top: 9px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
}
#register form .form-group i.icon_lock_alt {
  top: 10px;
}
#register .copy {
  text-align: center;
  position: absolute;
  padding-top: 10px;
  height: 30px;
  left: 0;
  bottom: 30px;
  width: 100%;
  color: #999;
}

.access_social {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .access_social {
    margin-top: 30px;
  }
}

.divider {
  text-align: center;
  height: 1px;
  margin: 30px 0 15px 0;
  background-color: #ededed;
}
.divider span {
  position: relative;
  top: -20px;
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  font-style: italic;
}

a.social_bt {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  min-width: 200px;
  margin-bottom: 5px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
a.social_bt:hover {
  -webkit-filter: brightness(115%);
  filter: brightness(115%);
}

/*Password strength */
#pass-info {
  width: 100%;
  margin-bottom: 15px;
  color: #555;
  text-align: center;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 3px 3px 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
#pass-info.weakpass {
  border: 1px solid #FF9191;
  background: #FFC7C7;
  color: #94546E;
}
#pass-info.stillweakpass {
  border: 1px solid #FBB;
  background: #FDD;
  color: #945870;
}
#pass-info.goodpass {
  border: 1px solid #C4EEC8;
  background: #E4FFE4;
  color: #51926E;
}
#pass-info.strongpass {
  border: 1px solid #6ED66E;
  background: #79F079;
  color: #348F34;
}
#pass-info.vrystrongpass {
  border: 1px solid #379137;
  background: #48B448;
  color: #CDFFCD;
}
