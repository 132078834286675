#error_page {
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #error_page img {
    width: 280px;
    height: auto;
  }
}
#error_page p {
  font-size: 21px;
  font-size: 1.3125rem;
}
@media (max-width: 767px) {
  #error_page p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (max-width: 767px) {
  #error_page {
    padding: 60px 0 0 0;
    height: 500px;
  }
}
